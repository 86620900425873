import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { InvoiceType } from '../enums/invoice-type.enum';

@Injectable()
export class InvoiceStateService {
  private parsedInvoiceData = new Subject<any>();
  private parsedInvoiceDataToRow = new Subject<any>();
  private invoiceErrors = new Subject<any>();
  private clearProjects = new Subject<any>();
  private loadingInvoices = new Subject<any>();
  private projectsDropdown = new Subject<any>();
  private invoiceTrueIds = new Subject<any>();
  private projectIdfromDropdown = new Subject<any>();
  private invoiceTypeState = new Subject<InvoiceType>();
  private invoiceExpiryDateState = new Subject<string>();
  private invoiceTypeMode = new Subject<any>();
  private projectToRemoveFromOpenProjectList = new Subject<any>();
  private loadedProjectsAtas = new Subject<any[]>();
  private selectedProjectsAtas = new Subject<string[]>();

  public currentInvoice: any = {};

  public parsedInvoiceData$ = this.parsedInvoiceData.asObservable();
  public parsedInvoiceDataToRow$ = this.parsedInvoiceDataToRow.asObservable();
  public invoiceErrors$ = this.invoiceErrors.asObservable();
  public clearProjects$ = this.clearProjects.asObservable();
  public invoiceTrueIds$ = this.invoiceTrueIds.asObservable();
  public loadingInvoices$ = this.loadingInvoices.asObservable();
  public invoiceTypeMode$ = this.invoiceTypeMode.asObservable();
  public invoiceTypeState$ = this.invoiceTypeState.asObservable();
  public invoiceExpiryDateState$ = this.invoiceExpiryDateState.asObservable();
  public projectsDropdown$ = this.projectsDropdown.asObservable();
  public projectIdfromDropdown$ = this.projectIdfromDropdown.asObservable();
  public projectToRemoveFromOpenProjectList$ =
    this.projectToRemoveFromOpenProjectList.asObservable();
  public getLoadedProjectsAtas = this.loadedProjectsAtas.asObservable();
  public getSelectedProjectsAtas = this.selectedProjectsAtas.asObservable();
  public taxReductionErrors: string;

  constructor() {
    this.clearProjects.next(false);
    this.loadingInvoicesState(false);
  }

  public changeInvoiceErrors(errors: any) {
    this.invoiceErrors.next(errors);
  }

  public changeInvoice(parsedInvoiceData: any) {
    this.currentInvoice = parsedInvoiceData;
    this.parsedInvoiceData.next(parsedInvoiceData);
    this.loadingInvoicesState(false);
  }

  public addInvoiceByRow = (parsedInvoiceData: any) => {
    this.currentInvoice = parsedInvoiceData;
    this.parsedInvoiceDataToRow.next(parsedInvoiceData);
  };

  public changeInvoiceType = (invoiceType: InvoiceType) =>
    this.invoiceTypeState.next(invoiceType);

  public changeInvoiceExpiry = (expiryDate: string) =>
    this.invoiceExpiryDateState.next(expiryDate);

  public changeInvoiceMode = invoiceModeArray =>
    this.invoiceTypeMode.next(invoiceModeArray);

  public invoiceTrueIdToBody = trueIdList =>
    this.invoiceTrueIds.next(trueIdList);

  public clearOpenedProjects = () => {
    this.clearProjects.next(true);
    setTimeout(() => this.clearProjects.next(false), 50);
  };

  public loadingInvoicesState = state => this.loadingInvoices.next(state);

  public selectedProjectOrInvoices = (values: string[]) =>
    this.projectIdfromDropdown.next(values);

  public removeFrmSelectedProjects = id =>
    this.projectToRemoveFromOpenProjectList.next(id.toString());

  public getProjectsDropdown = (projectArr: any[]) =>
    this.projectsDropdown.next(projectArr);

  public setLoadedProjectsAtas = (atas: any[]) =>
    this.loadedProjectsAtas.next(atas);

  public setSelectedProjectsAtasIds = (atasIds: string[]) =>
    this.selectedProjectsAtas.next(atasIds);

  public setTaxReductionErrors(taxReductionErrors: string) {
    this.taxReductionErrors = taxReductionErrors;
  }
}
